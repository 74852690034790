"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useObserveWindowWidth = void 0;
var useConstructor_1 = require("./useConstructor");
var useObserveSize_1 = require("./useObserveSize");
/**
 * Calls a function when this component mounts, and any time the window width changes. This is higher fidelity than the window resize event (which sometimes skips sizes which can result in unexpected behavior).
 *
 * @param callback The function that will be called.
 * @param deps The dependencies of the passed function.
 */
function useObserveWindowWidth(callback, deps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var ref = useObserveSize_1.useObserveSize(callback, deps);
    useConstructor_1.useConstructor(function () {
        ref(document.documentElement);
    });
}
exports.useObserveWindowWidth = useObserveWindowWidth;
