"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBackedState = void 0;
var react_1 = require("react");
var tuple_1 = require("./tuple");
var isCallable_1 = require("./isCallable");
function useBackedState(set, deps, get, defaultValue) {
    var _a;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var _b = __read(react_1.useState((_a = react_1.useMemo(get, [])) !== null && _a !== void 0 ? _a : defaultValue), 2), value = _b[0], setValue = _b[1];
    return tuple_1.tuple(value, react_1.useCallback(function (valueOrGetter) {
        if (isCallable_1.isCallable(valueOrGetter)) {
            setValue(function (value) {
                var newValue = valueOrGetter(value);
                set(newValue);
                return newValue;
            });
        }
        else {
            var newValue = valueOrGetter;
            set(newValue);
            setValue(newValue);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps));
}
exports.useBackedState = useBackedState;
