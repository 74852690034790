"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useObserveSize = void 0;
var react_1 = require("react");
var maybeResizeObserver = __importStar(require("resize-observer-polyfill"));
var throttle_1 = require("./throttle");
var ResizeObserver = (_a = 
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
maybeResizeObserver.default) !== null && _a !== void 0 ? _a : maybeResizeObserver;
/**
 * Calls a function when this component mounts, and any time the element to which the ref is attached resizes.
 *
 * @param callback The function that will be called.
 * @param deps The dependencies of the function (for caching).
 * @returns The ref callback to attach to the component to observe.
 */
function useObserveSize(callback, deps) {
    var _a = __read(react_1.useState(null), 2), element = _a[0], setElement = _a[1];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var cb = react_1.useMemo(function () { return throttle_1.throttle(callback); }, deps);
    react_1.useEffect(function () {
        if (element) {
            var observer_1 = new ResizeObserver(cb);
            cb([], observer_1);
            observer_1.observe(element);
            return function () { return observer_1.disconnect(); };
        }
        return undefined;
    }, [cb, element]);
    return setElement;
}
exports.useObserveSize = useObserveSize;
