import { Redirect, Route } from "react-router-dom"
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"

import * as AdHelper from "./helpers/AdHelper"

import { Home } from "./pages/home"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/variables.scss"

import { useConstructor } from "@ericbf/helpers/useConstructor"

setupIonicReact()

export const App: React.FC = () => {
	useConstructor(() => {
		AdHelper.init().then(() => {
			AdHelper.show()
		})
	})

	return (
		<IonApp>
			<IonReactRouter>
				<IonRouterOutlet>
					<Route exact path="/">
						<Home />
					</Route>
					<Route>
						<Redirect to="/" />
					</Route>
				</IonRouterOutlet>
			</IonReactRouter>
		</IonApp>
	)
}
