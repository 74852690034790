import { isPlatform } from "@ionic/react"
import { AdMob, BannerAdSize, BannerAdPosition } from "@capacitor-community/admob"

export async function show(): Promise<void> {
	if (!isPlatform("capacitor")) {
		return
	}

	AdMob.showBanner({
		adId: isPlatform("ios")
			? "ca-app-pub-8571544477525071/7063481074"
			: "ca-app-pub-8571544477525071/6871909384",
		adSize: BannerAdSize.BANNER,
		position: BannerAdPosition.BOTTOM_CENTER,
		margin: 0,
		isTesting: true
		// npa: true
	})
}

export async function init() {
	if (!isPlatform("capacitor")) {
		return
	}

	// const { status } = await AdMob.trackingAuthorizationStatus()

	// if (status === "notDetermined") {
	// 	/**
	// 	 * If you want to explain TrackingAuthorization before showing the iOS dialog,
	// 	 * you can show the modal here.
	// 	 * ex)
	// 	 * const modal = await this.modalCtrl.create({
	// 	 *   component: RequestTrackingPage,
	// 	 * });
	// 	 * await modal.present();
	// 	 * await modal.onDidDismiss();  // Wait for close modal
	// 	 **/
	// }

	AdMob.initialize({
		requestTrackingAuthorization: true,
		testingDevices: ["dff68df45c2aa7908bdd180d8131845c"],
		initializeForTesting: true
	})
}
