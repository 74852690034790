"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConstructor = void 0;
var react_1 = require("react");
/**
 * Call a callback only the first time through a component, at "constructor" time (before the first mount or anything).
 *
 * @param callback The callback to call the first time through this component.
 */
function useConstructor(callback) {
    var hasRun = react_1.useRef(false);
    if (!hasRun.current) {
        hasRun.current = true;
        callback();
    }
}
exports.useConstructor = useConstructor;
