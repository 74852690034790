"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocalStorageStateRef = void 0;
var react_1 = require("react");
var isCallable_1 = require("./isCallable");
var tuple_1 = require("./tuple");
var useLocalStorageState_1 = require("./useLocalStorageState");
function useLocalStorageStateRef(name, defaultValue) {
    var ref = react_1.useRef(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var _a = __read(useLocalStorageState_1.useLocalStorageState(name, defaultValue), 2), state = _a[0], setState = _a[1];
    return tuple_1.tuple(ref, react_1.useCallback(function (valueOrFunction) {
        if (isCallable_1.isCallable(valueOrFunction)) {
            setState(function (currentValue) {
                var value = valueOrFunction(currentValue);
                ref.current = value;
                return value;
            });
        }
        else {
            ref.current = valueOrFunction;
            setState(valueOrFunction);
        }
    }, [setState]), state);
}
exports.useLocalStorageStateRef = useLocalStorageStateRef;
