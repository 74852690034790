"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.throttle = void 0;
/**
 * Throttle the passed function. This means that the function will only fire once per threshold amount of time.
 *
 * @param func The function to be throttled.
 * @param threshold How long to wait before allowing the function to be triggered again. Default is `100`.
 * @returns A version of this function that will only trigger once per threshold amount of time.
 */
function throttle(func, threshold) {
    if (threshold === void 0) { threshold = 100; }
    var suppress = false;
    var delay;
    function clear() {
        suppress = false;
        if (delay) {
            delay();
            delay = undefined;
        }
    }
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (!suppress) {
            func.apply(this, args);
            setTimeout(clear, threshold);
            suppress = true;
        }
        else {
            delay = func.bind.apply(func, __spread([this], args));
        }
    };
}
exports.throttle = throttle;
