"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.storage = exports.storageFor = void 0;
/**
 * Create a wrapper around `localStorage` that stores values using `JSON.stringify` using a specific namespace.
 */
function storageFor(namespace) {
    var prefix = namespace + "\u2122\u00F8\u00E5";
    function p(key) {
        // Use some characters that are unlikely to be repeated.
        return "" + prefix + key;
    }
    return {
        get: function (key) {
            var string = localStorage.getItem(p(key));
            if (string != null) {
                try {
                    return JSON.parse(string);
                }
                catch (_a) { }
            }
            return undefined;
        },
        set: function (key, value) {
            var _a;
            // If something JSON cannot stringify is passed, it returns undefined. Let's make that save empty string instead, as localStorage would just strigify it.
            localStorage.setItem(p(key), (_a = JSON.stringify(value)) !== null && _a !== void 0 ? _a : "");
        },
        remove: function (key) {
            localStorage.removeItem(p(key));
        },
        has: function (key) {
            return Object.keys(localStorage).includes(p(key));
        },
        clear: function () {
            var e_1, _a;
            try {
                for (var _b = __values(Object.keys(localStorage)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var key = _c.value;
                    if (key.startsWith(prefix)) {
                        localStorage.removeItem(key);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
}
exports.storageFor = storageFor;
/**
 * A wrapper around `localStorage` that stores values using `JSON.stringify`.
 */
exports.storage = storageFor("\u00F8\u00DF\u221A");
