"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCallable = void 0;
/**
 * Get whether a thing is callable.
 *
 * @param thing The thing that is potentially callable.
 */
function isCallable(thing) {
    return typeof thing === "function";
}
exports.isCallable = isCallable;
