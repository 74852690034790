"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tuple = void 0;
/** Get the passed in items as a tuple. */
function tuple() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args;
}
exports.tuple = tuple;
